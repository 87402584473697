import React from "react"
import mobileapppng from "../../assets/images/features/mobile-app.png"
import mobileappbanner from "../../assets/images/banners/solutions-images/mobile-app.png"



import "../../assets/css/style.css"
import "../../assets/css/custom.css"
// import "../../assets/js/vendor/bootstrapp.min.js"
import "../../assets/css/vendor/vendor.min.css"
import "../../assets/css/plugins/plugins.min.css"

const MobileAppSolution = () => {
  return (
    <div id="main-wrapper">
      <div className="site-wrapper-reveal">
        <div
          className="about-banner-wrap banner-space bg-img mobile-app"
          data-bg=""
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8 m-auto">
                <div className="about-banner-content text-center">
                  <h1 className="mb-15 text-white">Mobile Apps Solutions</h1>
                  <h5 className="font-weight--normal text-white">
                    We will assist you from the first concept design of your app
                    through its launch on selected platforms, since we've
                    produced over 1000+ apps. We have the appropriate
                    combination of amazing technology, people, and experience to
                    create unique apps that work wonders for your organization.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--=========== About Company Area Start ==========-->
            <!--=========== Service Projects Wrapper Start =============--> */}
        <div className="service-projects-wrapper section-space--ptb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h6 className="section-sub-title">Our IT Solution</h6>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-xl-6 order-2 order-lg-1 v1-d-f">
                <div className="v1-dt-box-0-text">
                    <h2 className="heading">Mobile Application <span className="text-color-primary">Solutions</span></h2>
                    For the most popular enterprise and social messengers
                    get enterprise-ready mobile application solutions.
                  <div className="mt-20">
                    <p className="title" style={{ marginBottom: "0px" }}>
                      <i className="fas fa-check"></i> Mobile App Strategy
                    </p>
                    <p className="title" style={{ marginBottom: "0px" }} >
                      <i className="fas fa-check"></i> Mobile App Designing
                    </p>
                    <p className="title" style={{ marginBottom: "0px" }} >
                      <i className="fas fa-check"></i> Mobile App
                      Development
                    </p>
                    <p className="title" style={{ marginBottom: "0px" }} >
                      <i className="fas fa-check"></i> Support and
                      Maintenance
                    </p>
                    <p className="title" style={{ marginBottom: "0px" }} >
                      <i className="fas fa-check"></i> Mobile App
                      Marketing
                    </p>
                    <p className="title" style={{ marginBottom: "0px" }} >
                      <i className="fas fa-check"></i> Mobile App Porting
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-xl-6 order-1 order-lg-2">
                <div className="v1-dt-box-0-img">
                  <img className="img-fluid" src={mobileappbanner} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--=========== Service Projects Wrapper End =============-->

            <!--=========== About Company Area End ==========-->
            
            <!--========= More Services Start ===========--> */}
        <div className="about-delivering-wrapper section-space--pb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h2 className="heading">Process <span className="text-color-primary">We Follow</span></h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="delivering-optimal-wrap">
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title"> Requirement Gathering</h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title"> UI/UX Design </h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">Quality Assurance</h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">Support and Maintenance</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="delivering-optimal-wrap">
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">Prototype </h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">App development</h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">Deployment</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--========= More Services End ===========--> */}
      </div>
    </div>
  )
}

export default MobileAppSolution
