import * as React from "react"
import Layout from "../components/layout"
import MobileAppSolution from "../components/Solutions/MobileAppSolution"






const mobileapp = () => {
  return (
  <Layout>
   <MobileAppSolution/>
  </Layout>
)
};
export default mobileapp;
